import React from "react";

export default function ListingsButton() {

    return (
        <>
        <span id='dropdown-listings' >Manage Listings</span>
        </>
    )
}
